import * as React from 'react'

import {
  HorizontalAlignment,
  i18n,
  useTheme,
} from '@thg-commerce/enterprise-core'
import {
  Carousel,
  CarouselButtonPlacement,
} from '@thg-commerce/gravity-patterns'

import { ImageCard, ImageCardProps } from './ImageCard'
import { CarouselContainer, Container, MainSubtitle, MainTitle } from './styles'
import { CardType, TextProps } from './types'

export interface ImageCardCarouselProps {
  title?: TextProps
  subtitle?: TextProps
  horizontalAlignment?: HorizontalAlignment
  cardType: CardType
  imageCards: ImageCardProps[]
}

export const ImageCardCarousel = (props: ImageCardCarouselProps) => {
  const theme = useTheme()

  const carouselText = {
    leftScrollLabel: i18n('carousel.controls.left.arialabel'),
    rightScrollLabel: i18n('carousel.controls.right.arialabel'),
  }

  if (props.imageCards.length < 2) {
    return null
  }

  const cardItems = props.imageCards.map((imageCardProps, index) => {
    return (
      <ImageCard
        {...imageCardProps}
        cardType={props.cardType}
        key={index}
        noGridGap={theme.widget.imageCardSet.carousel.alternativeStyle}
      />
    )
  })

  return (
    <Container>
      {props.title && (
        <MainTitle
          horizontalAlignment={props.horizontalAlignment || 'center'}
          textColor={props.title.color || 'dark'}
        >
          {props.title.text}
        </MainTitle>
      )}
      {props.subtitle && (
        <MainSubtitle
          horizontalAlignment={props.horizontalAlignment || 'center'}
          textColor={props.subtitle.color || 'dark'}
          marginBottom={props.subtitle.marginBottom}
        >
          {props.subtitle.text}
        </MainSubtitle>
      )}
      <CarouselContainer>
        <Carousel
          items={cardItems}
          i18n={carouselText}
          itemsPerSlide={[1, 2, 3, 4]}
          hideControlsOnSingleSlide={true}
          inactiveSlideFullOpacity={true}
          buttonPlacement={
            theme.widget.imageCardSet.carousel.buttonPlacement
              ? theme.widget.imageCardSet.carousel.buttonPlacement
              : CarouselButtonPlacement.Split
          }
          indicatorStyle={
            theme.widget.imageCardSet.carousel?.pageIndicator?.style
          }
          chevronIconStyle={theme.widget.imageCardSet.carousel.chevronIconStyle}
          controls={{
            alternativeChevronStyling:
              theme.widget.imageCardSet.carousel.alternativeStyle,
          }}
          swipeable={true}
        />
      </CarouselContainer>
    </Container>
  )
}
